import { clearSessionStorage } from './sessionStorageKeys';

clearSessionStorage();

export default async ({ sparkyFramework, vars }) => {
  // eslint-disable-next-line no-undef
  const envName = (vars ?? {}).env_name || process.env.ENV_NAME;
  // eslint-disable-next-line no-undef
  console.info(`ADEM UI Env: ${envName}`);
  // eslint-disable-next-line no-undef
  console.info(`ADEM UI Version: ${process.env.APP_VERSION}`);

  if (envName === 'staging') {
    // eslint-disable-next-line no-undef
    console.info(
      '%c Release Changelog: ',
      'background: #222; color: #bada55',
      `https://prisma-access.docs.pan.run/sase-adem-group/dem-ui/changelog.txt`
    );
  }

  return async ({ auth }) => {
    const { setUpManager } = await import('./index.utils');
    const { FeatureManager } = await import('./api/common/featureMapping');
    console.log('TenantInfo', auth?.tsg_id, vars);

    await setUpManager(sparkyFramework, auth, vars);

    const featureData = FeatureManager.getFeatureList();

    return {
      navigation: () => [
        {
          key: 'settings',
          title: 'Settings',
          path: '/settings',
          noRBACheck: true,
          expandable: true,
          mergeNav: true,
          children: [
            {
              key: 'dem-settings',
              title: 'Application Experience',
              contentClassName: 'dem-settings',
              path: '/settings/dem-settings',
              exact: true,
              noRBACheck: true,
              hidden:
                featureData.length > 0
                  ? !FeatureManager.isFeatureEnabled(
                      'panw_sase:settings_system_application'
                    )
                  : false,
              component: () =>
                import('./saseViews/settingsView/SettingsViewWrapper'),
            },
          ],
        },
        {
          key: 'monitor',
          title: 'Monitor',
          path: '/monitor',
          noRBACheck: true,
          expandable: true,
          mergeNav: true,
          children: [
            {
              key: 'monitor-palocations',
              title: 'Prisma Access Locations',
              contentClassName: 'monitor-palocations',
              path: '/monitor/palocations',
              exact: false,
              noRBACheck: true,
              hidden:
                featureData.length > 0
                  ? !FeatureManager.isFeatureEnabled('panw_sase:pa_location')
                  : false,
              component: () =>
                import(
                  './saseViews/prismaLocationView/PrismaLocationViewWrapper'
                ),
            },
            {
              key: 'monitor-users',
              title: 'Users',
              contentClassName: 'monitor-users',
              path: '/monitor/users',
              exact: false,
              noRBACheck: true,
              hidden:
                featureData.length > 0
                  ? !FeatureManager.isFeatureEnabled('panw_sase:users')
                  : false,
              component: () => import('./saseViews/usersView/UsersViewWrapper'),
            },
            {
              key: 'monitor-dem-application',
              title: 'Prisma Access Dem Application',
              contentClassName: 'monitor-dem-application',
              path: '/monitor/applications/dem-application',
              exact: false,
              noRBACheck: true,
              hidden: true,
              // AppDetailsViewWrapper contains:
              // 1. App Experience Details
              // 2. Manage App Tests
              // 3. Edit/New App Test
              component: () =>
                import('./saseViews/appsView/AppDetailsViewWrapper'),
            },
            {
              key: 'monitor-remote-sites',
              title: 'Prisma Access Site Details',
              contentClassName: 'monitor-site-details',
              path: '/dem/dem-remote-sites/details',
              exact: true,
              hidden: true,
              noRBACheck: true,
              component: () =>
                import(
                  './saseViews/sitesView/SiteDetails/SiteDetailsViewWrapper'
                ),
            },
          ],
        },
      ],
      exposes: {
        default: {
          DemSummaryViewWrapper: () =>
            import('./saseViews/summaryView/SummaryViewWrapper'),
          DemSettingsViewWrapper: () =>
            import('./saseViews/settingsView/SettingsViewWrapper'),
          PrismaLocationViewWrapper: () =>
            import('./saseViews/prismaLocationView/PrismaLocationViewWrapper'),
          ApplicationsViewWrapper: () =>
            import('./saseViews/appsView/AppsViewWrapper'),
          DemDeviceExpCard: () =>
            import('./saseViews/components/deviceExpCard/index'),
          IncidentExperienceTrend: () =>
            import('./saseViews/incidentsView/IncidentExperienceTrendWrapper'),
          SiteApplicationExpScore: () =>
            import(
              './saseViews/sitesView/SitesApplicationExpScore/SitesAppExpScoreWrapper'
            ),
          DemSiteDetailsViewWrapper: () =>
            import('./saseViews/sitesView/SiteDetails/SiteDetailsViewWrapper'),
        },
      },
    };
  };
};
